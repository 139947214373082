<div class="app-main-container" @fadeAnimation>
    <app-banner [pictureUrl]="getFilePath()"></app-banner>
    <div class="main-content">
        <div class="header-container">
            <span class="header-symbol-left">&#8828;</span>
            <h1>Hvad er filosofisk terapi</h1>
            <span class="header-symbol-right">&#8829;</span>
        </div>
        <div class="text-content">
            <p>Filosofisk terapi starter med at identificere den tænkning, der fører os til vanskeligheder i livet, og
                undersøger derefter alternative perspektiver, der kan åbne usete muligheder og fjerne hindringerne, der
                har forhindret os i at komme positivt frem i vores liv.</p>
            <p>Filosofiens magt i denne sammenhæng ligger i dens aktivitet at stille grundlæggende og eksistentielle
                spørgsmål. Når vi føler os fast eller tabt, skyldes det ofte perspektiver, overbevisninger og værdier,
                der synes så basale, at vi ikke engang ser en mulighed for at revurdere dem.</p>
            <p>Filosofer er trænet til at stille gode spørgsmål, spørgsmål, der kan hjælpe os med at opdage de grænser,
                som
                vores personlige 'tankevaner' opsætter den måde vi ser og oplever verden på. Når vi har afsløret og
                formuleret disse, åbner vi et rum for at udvikle mere solide, bevidste og styrkende intellektuelle
                synspunkter.</p>
            <p>På denne måde kan filosofisk visdom hjælpe os med at komme videre ved at gøre os mentalt stærkere og
                befri
                os fra vores egne ubestridte antagelser såvel som andres.</p>
            <p>Den mest magtfulde måde at ændre vores holdninger, adfærd og følelser på er ved at ændre den måde vi
                tænker
                på. Filosofi er bestræbelsen på at tænke gennem vores menneskelige oplevelser dybt, omhyggeligt og med
                åbenheden for at ændre vores sind.</p>
            <p> Filosofisk terapi sætter denne form for tænkning i tjeneste for rigere,
                mere produktive og tilfredsstillende liv, hvilket giver os værktøjer til at løse problemer mere
                selvsikker
                og til at leve et mere bevidst og givende liv.
            </p>
        </div>
    </div>
</div>