import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from 'src/app/core/animations/FadeAnimation';

@Component({
  selector: 'app-therapy',
  templateUrl: './therapy.component.html',
  styleUrls: ['./therapy.component.scss'],
  animations: [FadeAnimation]
})
export class TherapyComponent implements OnInit {
  public isHandset: boolean;
  constructor(private breakPointObserver: BreakpointObserver) {
    this.breakPointObserver.observe(Breakpoints.Handset).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
  }

  ngOnInit(): void {
  }
  public getFilePath(): string {
    return '../../../assets/img/StoiskRo-38.jpg';
  }
}
