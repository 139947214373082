import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from 'src/app/core/animations/FadeAnimation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [FadeAnimation]
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {}
  public home(): void {
    this.router.navigate(['']);
  }

}
