import { Component, OnInit, Input } from '@angular/core';
import { FadeAnimation } from '../../core/animations/FadeAnimation';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [FadeAnimation],
})
export class BannerComponent implements OnInit {
  @Input()
  pictureUrl: string;
  constructor() {}

  ngOnInit(): void {}
}
