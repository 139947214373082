import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from 'src/app/core/animations/FadeAnimation';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
  animations: [FadeAnimation]
})
export class ArticlesComponent implements OnInit {
  public cols: number = 3;
  public ratio: string = "1:2";
  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      if (result.matches) {
        this.cols = 2;
        this.ratio = "1:3"
      } else {
        this.cols = 3;
        this.ratio = "1:2"
      }
    });
  }

  ngOnInit(): void {
  }
  public getFilePath(): string {
    return '../../../assets/img/StoiskRo-2.jpg';
  }
}
