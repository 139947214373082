<div class="app-main-container" @fadeAnimation>
    <app-banner [pictureUrl]="getFilePath()"></app-banner>
    <div class="main-content">
        <div class="header-container">
            <span class="header-symbol-left">&#8828;</span>
            <h1>Filosofisk samtaleterapi &amp; Kognitiv adfærdsterapi</h1>
            <span class="header-symbol-right">&#8829;</span>
        </div>
        <div class="text-content">
            <p>
                Stoisk Ro handler om at finde sindsro og leve i trivsel – Det gode liv. Vi lever i en verden,
                hvor vi alle har en tendens til at blive frustreret og ked af det over lang tid, uden at vide
                hvorfor. Vi har fra barnsben været ude for et hav af udfordringer, som har været med til at
                præge vores tanker, følelser, adfærd og krop. Og mange af de negative tanker udspringer
                af vores måde at (mis)fortolke verden på. Når vi tager fejl af vores virkelighed, bliver vi
                frustreret, vred eller ked af det. Vi lider for det meste, fordi vores forventinger og
                overbevisninger ikke bliver mødt med vores virkelighed.
            </p>
            <p>
                For at undgå konstant at falde i de negative tanker, opfordrer den kognitive terapi os til at
                fokusér på, hvad vi kan kontrollere, og hvad vi ikke kan kontrollere. På denne måde, vil vi
                lettere kunne omrokere vores tanker og adfærd og danne en ny rational vane. På denne
                måde vil vi have kontrol over vores tanker, følelser og opførsel. Både REBT &amp; CBT
                udspringer af stoicisme. Begge ophavsmænd til hver af disse terapier hviler sig op ad
                Epiktets berømte læresætning,
            </p>

            <br>
            <p class="quote">“Det er ikke selve tingene, som er forstyrrende, men hvordan vi iagttager dem”.</p>
            <br>
            <p>
                Det er udelukkende af denne læresætning, jeg arbejder ud fra. Følelsesmæssig
                forstyrrelse er resultatet af, at man sindsløs (uden overvejelser) bliver optaget af ydre
                begivenheder, bliver overdreven knyttet til sanselyst, begær, rigdom og andres ros og
                overdrevent bange for smerte, fattigdom og kritik. Ved at dreje opmærksomheden på
                vores egne beslutninger, kan vi ændre måden vi ser på livet, vurdere de værdier vi
                tillægger ting, og genvinde kontrol over vores følelser. Vi er først frie, når vi er i kontrol
                over egen følelser og tanker. At opnå lykke er enhvers naturlig mål. Det er den foretrukne
                rute til enhver, som har lyst til at leve et godt meningsfuld liv.
            </p>
            <br>
            <p>Stoisk Ro arbejder med mennesker som er udfordret med:</p>
            <br>
            <div [ngClass]="{'therapy-selection-container': !isHandset, '': isHandset}">
                <mat-chip-list class="mat-chip-list-stacked" aria-label="therapy selection">
                    <mat-chip>Stress</mat-chip>
                    <mat-chip>Vrede</mat-chip>
                    <mat-chip>Død</mat-chip>
                </mat-chip-list>
                <mat-chip-list class="mat-chip-list-stacked" aria-label="therapy selection">
                    <mat-chip>Angst</mat-chip>
                    <mat-chip>Depression</mat-chip>
                    <mat-chip>Sygdom</mat-chip>
                </mat-chip-list>
                <mat-chip-list class="mat-chip-list-stacked" aria-label="therapy selection">
                    <mat-chip>PTSD</mat-chip>
                    <mat-chip>Lavt selværd</mat-chip>
                    <mat-chip>Psykisk vold</mat-chip>
                </mat-chip-list>
            </div>
            <br>
            <br>
            <p class="quote">”At kunne tale med sig selv er en af de største egenskaber vi mennesket har fået som gave”.
            </p>
            <p>
                Kognitiv adfærdsterapi arbejder med klienter med at tackle irrationelle eller forstyrrende
                følelser, og i stedet kultivere en mere rational, sund og proportionelle.
            </p>
            <br>
            <p>
                Klienten bliver hjulpet af terapeuten til at gøre følgende:
            </p>
            <ul>
                <li>
                    <p>At få overblik over ens negative automatiske tanker</p>
                </li>
                <li>
                    <p>At evaluere forholdet mellem ens tanker, følelser og handlinger
                    <p>
                </li>
                <li>
                    <p>Forsigtig evaluere bevis(erne) for og imod ens forvrænget og uhensigtsmæssige
                    <p>erkendelse
                    <p>
                </li>
                <li>
                    <p>At fremkalde en alternative erkendelse og erstatte den ovenpå de negative
                    <p>
                </li>
                <li>
                    <p>At identificere og modificere underliggende dysfunktionelle antagelser og
                        overbevisninger, som (typisk) medfører til negative automatiske tanker
                    <p>
                </li>
            </ul>
            <br>
            <p>Konsultation varighed er 55 minutter. Her vil klienten få: </p>
            <br>
            <div>
                <mat-chip-list class="mat-chip-list-stacked" aria-label="therapy selection">
                    <mat-chip>Psyko-edukation</mat-chip>
                    <mat-chip [ngClass]="{'rofl': isHandset}">Oplæg omkring stoicisme og de kognitive redskaber
                    </mat-chip>
                    <mat-chip>Dialog</mat-chip>
                    <mat-chip>Øvelser (eksponeringsøvelser)</mat-chip>
                    <mat-chip>Opfølgning</mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
</div>