<div class="app-main-container" @fadeAnimation>
  <app-banner [pictureUrl]="getFilePath()"></app-banner>
  <div class="main-content">
    <div class="header-container">
      <span class="header-symbol-left">&#8828;</span>
      <h1>Artjom Aroutjounjan, filosofisk samtaleterapeut</h1>
      <span class="header-symbol-right">&#8829;</span>
    </div>
    <div class="text-content">
      <p>
        Jeg ved godt, at det kan være svært at finde ro i hverdagen. De fleste af os lever i en konstant
        myldretid, og alle vores personlige såvel som professionelle forhold bliver forstyrret af manglen på
        indre stabilitet.
      </p>
      <p>
        Det kan være svært blot at være tilstede; At være nærværende med dem, du elsker, og præstere dit
        fulde potentiale på arbejdspladsen.
      </p>
      <p>
        Jeg er her for at forstå dig, og give dig muligheden for at forstå dig selv. Du er ikke alene om dine
        bekymringer, hvis bare du søger hjælp.
      </p>

      <br>
      <p class="section-header">Min baggrund</p>
      <p>
        Igennem hele mit liv har jeg været optaget af mennesket og menneskets psyke. Min egen hårde
        barndomsopvækst har givet mig masser af viden og erfaring indenfor den psykiske lidelsesverden.
        Jeg kastede mig derfor ud i filosofien, og den viste mig, hvordan vi etisk kan leve vores liv bedst
        muligt.
      </p>
      <br>
      <p class="quote">“For at vi kan finde ro og fred i livet, har vi til opgave at forstå os selv”</p>
      <br>
      <p>
        <b>H</b>vad kan styrke os?
      </p>
      <p>
        <b>H</b>vad kan forbedre os psykisk, så vi kan udholde livet?
      </p>
      <p>
        <b>H</b>vilke resurser bærer vi rundt på, som kan give os styrke til ikke at miste modet?
      </p>
      <p>
        Alle disse store spørgsmål førte mig frem til <b><i>stoicisme</i></b> - en filosofisk gren, der sigter efter
        sindsro
        og visdom. Ved at studere alle livets vinkler fandt jeg ud af, at for at vi kan finde ro og fred i livet,
        har vi til opgave at forstå os selv. Denne selvforståelse udspringer af at være nysgerrig, være
        interesseret i hvem vi er <i>kollektivt</i>, jage drømme og ikke mindst søge visdom i naturen.
      </p>
      <br>
      <p class="section-header">De nødvendige redskaber</p>
      <p>Til trods for min svære opvækst som flygningebarn med vold og uforudsigeligheder fandt jeg en
        livsfilosofi, som dannede mig til hvem jeg er i dag. Jeg fandt visdom hos nogle store tænkere som
        Sokrates, Epiktetus, Marcus Aurelius og Seneca m.fl., og brugte mine erfaringer og min nyvundne
        viden i mit arbejde med uledsagede flygtningebørn hos Røde Kors.</p>
      <p>Hér arbejdede jeg med unge mennesker, der bl.a. led af stress, PTSD, depression, angst og lavt
        selvværd.</p>
      <p>Under mit arbejde formåede jeg at rykke i disse unge mennesker, og gav dem de nødvendige
        redskaber de skulle bruge, for at få et nyt perspektiv på livet. Jeg inviterede dem til fælles samtaler
        om filosofi, til at spille skak og crossfit, og introducerede dem til andre kulturelle oplevelser.</p>

      <br>
      <!-- <h2>Foredragsholder</h2>
      <p>Jeg er også foredragsholder, og tilbyder foredrag og workshops til virksomheder, institutter og
        organisationer. Jeg arbejder tæt sammen med ledere, som ønsker at gøre en forskel for
        medarbejderne og arbejdspladsen.</p> -->

      <br>
      <p class="section-header">Til mine klienter</p>
      <p>
        Jeg har etableret min egen terapiform, Stoisk Ro, der har fokus på menneskets løsrivelse af sine
        lidelser.
      </p>
      <p>
        Filosofisk samtaleterapi tilbyder en livsfilosofi, som mange helt almindelige mennesker har brug
        for. Hidtil har jeg haft tilfredse klienter, som på kort tid har fået indsigt i, hvad der blokerer dem, og
        hvad de kan gøre for at komme ud af deres psykiske hindringer.
      </p>
      <p>Jeg respekterer mennesket og alt dét, mennesket er i stand til ved brug af fornuft og passion. Men
        intet kommer af sig selv. Med den rette vejledning kan vi sammen finde din egen vej ind i
        selvudvikling.</p>
      <br>
    </div>
  </div>
</div>