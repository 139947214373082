import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from 'src/app/core/animations/FadeAnimation';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [FadeAnimation]
})
export class ContactComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  public getFilePath(): string {
    return '../../../assets/img/StoiskRo-47.jpg';
  }
}
