<div class="app-main-container" @fadeAnimation>
    <app-banner [pictureUrl]="getFilePath()"></app-banner>
    <div class="main-content" style="height: 400px;">
        <div class="header-container" style="padding-bottom: 50px;">
            <span class="header-symbol-left">&#8828;</span>
            <h1>Kontakt</h1>
            <span class="header-symbol-right">&#8829;</span>
        </div>
        <div class="card-container">

            <mat-card>
                <div class="logo-container">
                    <img class="logo" src="../../../assets/logo/logo_v2.svg">
                </div>
                <div class="card">
                    <div class="card-header">
                        <span style="font-weight: bold;">FILOSOFISK SAMTALETERAPI</span>
                    </div>
                    <div class="card-content">
                        <span style="font-weight: bold;">
                            Artjom Aroutounjan
                            <hr>
                        </span>
                        <a style="color:black; text-decoration: none;" href="tel:+4520319612">Tlf. +45 2031 9612</a>
                        <span>www.stoisk-ro.dk</span>
                        <a style="color:black; text-decoration: none;"
                            href="mailto:stoisk.pleje@gmail.com">stoisk.pleje@gmail.com</a>
                        <div>
                            <a style="color: black; text-decoration: none;"
                                href="https://www.facebook.com/groups/1987044288072840/" target="_blank"><i
                                    class="fa fa-facebook-square socials"
                                    style="margin-right: 5px;     color: #666690;"></i>Sunspect</a>
                            <a style="color: black; text-decoration: none;"
                                href="https://www.instagram.com/sunspect_/?igshid=n8br8f0ds2wv" target="_blank"><i
                                    class="fa fa-instagram socials"
                                    style="margin-left: 10px; margin-right: 5px; color: #e0435e;"></i>sunspect_</a>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>