import { animate, style, transition, trigger } from '@angular/animations';

export const FadeAnimation = [
    trigger('fadeAnimation', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('0.3s 0.3s', style({ opacity: 1 })),
        ]),
        transition(':leave', [
            animate('0s', style({ opacity: 0 }))
        ])
    ])
];
