<div class="app-header-container" @fadeAnimation>
    <div class="app-header-logo-container" (click)="home()">        
        <p class="motto">FILOSOFISK</p>
        <P class="motto">SAMTALETERAPI</P>
    </div>
    <div class="app-menu-container">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon class="menu-icon">menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['']" >
              <span>FORSIDE</span>
            </button>
            <button mat-menu-item [routerLink]="['therapy']">
              <span>TERAPI</span>
            </button>
            <button mat-menu-item disabled>
              <span>FORDRAGSHOLDER</span>
            </button>
            <button mat-menu-item disabled>
              <span>ARTIKLER OG EMNER</span>
            </button>
            <button mat-menu-item [routerLink]="['bio']">
              <span>BIO</span>
            </button>
            <button mat-menu-item  [routerLink]="['contact']">
              <span>KONTAKT</span>
            </button>
          </mat-menu>
    </div>
</div>