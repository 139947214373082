<div class="app-main-container" @fadeAnimation>
    <app-banner [pictureUrl]="getFilePath()"></app-banner>
    <div class="main-content">
        <div class="header-container">
            <span class="header-symbol-left">&#8828;</span>
            <h1>Artikler & Emner</h1>
            <span class="header-symbol-right">&#8829;</span>
        </div>
        <mat-grid-list [cols]="cols" [rowHeight]="ratio">
            <mat-grid-tile>
                <div class="tile-conten">
                    <img class="article-image" src="../../../assets/articles/valotton.JPG">
                    <p class="catagory">Selvindsigt</p>
                    <p class="header-content">Om at vende ryggen til sin feminine side - Enhver mand bærer sin Eva i sig
                    </p>
                    <p class="preview">Hvad gør en mand til Mand? “I gamle dage” havde man den gængse mening, om hvad
                        det
                        vil sige at være en mand. Historisk set er manddom knyttet til at være robust, stærk, sej,
                        modig...</p>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="tile-conten">
                    <img class="article-image"
                        src="https://scontent.faar2-1.fna.fbcdn.net/v/t1.0-9/89037400_10157555310958889_7410615333628674048_o.jpg?_nc_cat=111&_nc_sid=825194&_nc_ohc=_438aReyH-YAX8JFxDk&_nc_ht=scontent.faar2-1.fna&oh=5304efa9f43834b444f2fa5fca7b7330&oe=5F6F66FE">
                    <p class="catagory">Selvindsigt</p>
                    <p class="header-content">Om at vende ryggen til sin feminine side - Enhver mand bærer sin Eva i sig
                    </p>
                    <p class="preview">Hvad gør en mand til Mand? “I gamle dage” havde man den gængse mening, om hvad
                        det
                        vil sige at være en mand. Historisk set er manddom knyttet til at være robust, stærk, sej,
                        modig...</p>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="tile-conten">
                    <img class="article-image"
                        src="https://scontent.faar2-1.fna.fbcdn.net/v/l/t1.0-9/67760918_10156951878488889_5133293793478443008_o.jpg?_nc_cat=106&_nc_sid=825194&_nc_ohc=SC3AfDadv30AX_wKN9h&_nc_ht=scontent.faar2-1.fna&oh=ddd7b021bfa009d2dfbd14693183f492&oe=5F6E4BD3">
                    <p class="catagory">Selvindsigt</p>
                    <p class="header-content">Om at vende ryggen til sin feminine side - Enhver mand bærer sin Eva i sig
                    </p>
                    <p class="preview">Hvad gør en mand til Mand? “I gamle dage” havde man den gængse mening, om hvad
                        det
                        vil sige at være en mand. Historisk set er manddom knyttet til at være robust, stærk, sej,
                        modig...</p>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>